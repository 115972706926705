/**
 * Component
 * CODE BLOCK
 */

.code-block {
  background: #1e221d;
  color: $white;
  font-size: 0.9375rem;
  line-height: 1.6em;
  margin: 15px 0 30px;
  position: relative;
  width: auto;

  &.collapse.in,
  &.collapsing {
    margin-left: 0;
  }

  &:after {
    content: 'HTML';
    position: absolute;
    top: 0;
    left: 0;
    color: $asbestos;
    border-right: 1px solid $asbestos;
    border-bottom: 1px solid $asbestos;
    border-bottom-right-radius: 4px;
    padding: 5px 8px;
    display: inline-block;
    font-family: $mainFont;
  }

  &.js-example:after {
    content: 'JavaScript';
  }

  pre,
  code {
    font-family: $codeFont;
    width: auto;
    background: #1e221d;
    word-break: keep-all;
    word-wrap: normal;
    white-space:pre;
    margin: 0px;
    padding: 0px;
    font-size: inherit;
    position: relative;
    line-height: 1.6em;
    color: $white;
    border: none;
    overflow: visible;
  }

  pre {
    padding: 50px 4% 40px;
    overflow-x: auto;
  }

  @media only screen and (max-width: 900px) {
    padding: 50px 10px;
  }
}
