/**
 * STYLEGUIDE DEDICATED STYLE
 */
/* http://prismjs.com/download.html?themes=prism-okaidia&languages=markup+css+clike+javascript+sass+scss */
/**
 * okaidia theme for JavaScript, CSS and HTML
 * Loosely based on Monokai textmate theme by http://www.monokai.nl/
 * @author ocodia
 */
code[class*="language-"],
pre[class*="language-"] {
  color: #f8f8f2;
  text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none; }

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: .5em 0;
  overflow: auto;
  border-radius: 0.3em; }

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #272822; }

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
  white-space: normal; }

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray; }

.token.punctuation {
  color: #f8f8f2; }

.namespace {
  opacity: .7; }

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
  color: #f92672; }

.token.boolean,
.token.number {
  color: #ae81ff; }

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #a6e22e; }

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
  color: #f8f8f2; }

.token.atrule,
.token.attr-value,
.token.function {
  color: #e6db74; }

.token.keyword {
  color: #66d9ef; }

.token.regex,
.token.important {
  color: #fd971f; }

.token.important,
.token.bold {
  font-weight: bold; }

.token.italic {
  font-style: italic; }

.token.entity {
  cursor: help; }

/**
 * Object
 * COMPONENT
 */
.component + .component {
  margin-top: 40px; }

.component-heading {
  margin-top: 0; }
  @media screen and (min-width: 480px) {
    .component-heading {
      float: left; } }

.component-anchor, .component-anchor:hover, .component-anchor:focus, .component-anchor:visited, .component-anchor:active {
  color: inherit;
  text-decoration: none; }

.component-anchor:focus, .component-anchor:hover {
  text-decoration: underline; }

.component-label {
  position: relative;
  top: -2px; }
  @media screen and (max-width: 480px) {
    .component-label {
      float: left;
      margin-bottom: 10px; } }
  @media screen and (min-width: 480px) {
    .component-label {
      margin-left: 12px; } }

.component-nav {
  margin: 0; }
  .component-nav li {
    margin: 0; }
  @media screen and (max-width: 480px) {
    .component-nav {
      float: left;
      margin-bottom: 10px; } }
  @media screen and (min-width: 480px) {
    .component-nav {
      float: right;
      margin-right: -5px; } }

.component-release-notes.collapse.in, .component-release-notes.collapsing {
  margin-left: 0; }

.component-release-notes p + ul {
  margin-top: 0; }
  .component-release-notes p + ul + p {
    margin-top: 10px; }

.component-example {
  padding: 50px 4% 40px 4%;
  border: 1px solid #f2f1ea;
  position: relative;
  width: 100%;
  overflow-y: visible;
  box-sizing: border-box;
  position: relative; }
  .component-example:after {
    content: 'Example';
    position: absolute;
    top: 0;
    left: 0;
    color: #7F8C8D;
    border-right: 1px solid #f2f1ea;
    border-bottom: 1px solid #f2f1ea;
    border-bottom-right-radius: 4px;
    padding: 5px 8px;
    display: inline-block;
    font-family: "Frutiger Neue Regular", sans-serif; }
  .component-example + .code-block {
    margin-top: 0; }

.show-code {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  z-index: 50;
  border: none;
  color: #CCCCCC;
  background: transparent;
  font-family: "Frutiger Neue Regular", sans-serif; }
  .show-code:hover, .show-code:focus {
    color: #DC0018; }

/**
 * SPECIFIC COMPONENTS
 */
[id="02-general-03-background"] .component-example {
  background: url("/img/bg-striped.png") repeat; }

/**
 * Object
 * LABEL
 */
.label-admin {
  display: inline-block;
  width: 31px;
  height: 31px;
  padding: 0;
  color: #000;
  font-size: 0.5rem;
  line-height: 31px;
  text-align: center;
  vertical-align: middle;
  border-radius: 31px;
  border: 1px solid #000; }
  @media only screen and (max-width: 991px) {
    .label-admin {
      width: 27px;
      height: 27px;
      line-height: 27px; } }

/**
 * Component
 * CODE BLOCK
 */
.code-block {
  background: #1e221d;
  color: #fff;
  font-size: 0.9375rem;
  line-height: 1.6em;
  margin: 15px 0 30px;
  position: relative;
  width: auto; }
  .code-block.collapse.in, .code-block.collapsing {
    margin-left: 0; }
  .code-block:after {
    content: 'HTML';
    position: absolute;
    top: 0;
    left: 0;
    color: #7F8C8D;
    border-right: 1px solid #7F8C8D;
    border-bottom: 1px solid #7F8C8D;
    border-bottom-right-radius: 4px;
    padding: 5px 8px;
    display: inline-block;
    font-family: "Frutiger Neue Regular", sans-serif; }
  .code-block.js-example:after {
    content: 'JavaScript'; }
  .code-block pre,
  .code-block code {
    font-family: Monaco, monospace;
    width: auto;
    background: #1e221d;
    word-break: keep-all;
    word-wrap: normal;
    white-space: pre;
    margin: 0px;
    padding: 0px;
    font-size: inherit;
    position: relative;
    line-height: 1.6em;
    color: #fff;
    border: none;
    overflow: visible; }
  .code-block pre {
    padding: 50px 4% 40px;
    overflow-x: auto; }
  @media only screen and (max-width: 900px) {
    .code-block {
      padding: 50px 10px; } }

/**
 * Object
 * COLOR SWATCH
 */
.color-swatch {
  display: block;
  padding: 20px 10px;
  position: relative;
  text-align: center;
  border-radius: 3px; }
  .color-swatch::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    right: 0;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
    border-radius: 3px; }
